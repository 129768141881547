/**
 * Méthodes d'authentification et langue (login/logout/setLang)
 */
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';

import i18n from '../i18n';
import {
  LOGIN,
  LOGOUT,
  LOGIN_RESET,
  SET_LANGUAGE,
  PING,
  OFFLINE,
  ONLINE,
  HAS_OFFLINE_QUERY,
  INFO,
  HAS_REPLAYED_QUERIES,
} from './const/actions.type';
import {
  PURGE_AUTH, SET_AUTH, SET_ERROR, PURGE_DATA, INIT_APP, SET_OFFLINE, SET_HAS_OFFLINE_QUERY,
} from './const/mutations.type';

const DEFAULT_EXPIRATION = 60 * 60 * 24 * 1; // 1 day

const stateAuth = {
  errors: null,
  user: {},
  token: {},
  isAuthenticated: false,
  isOffline: false,
  hasOfflineQuery: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentLang(state) {
    return state.user.lang ? state.user.lang.toLowerCase() : 'fr';
  },
  isAuthenticated(state) {
    // console.log('isAuthenticated', state, state.timestamp + state.expires_in > Date.now());
    // return !!state.token; // && state.timestamp + state.expires_in > Date.now();
    return state.isAuthenticated && !!state.token;
  },
  isOffline(state) {
    return state.isOffline;
  },
  getToken(state) {
    return state.token;
  },
  hasOfflineQuery(state) {
    return state.hasOfflineQuery;
  },
};

// eslint-disable-next-line import/prefer-default-export
const actions = {
  [PING]() {
    ApiService.setHeader();
    return ApiService.get('ping/');
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('login/', credentials).then(({ data }) => {
        // Decodons le token JWTar
        const { token } = data;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const jsonContent = JSON.parse(window.atob(base64));
        context.commit(SET_AUTH, { token, user: jsonContent });
        context.commit(INIT_APP, { token, user: jsonContent });
        resolve(data);
      }).catch(({ response }) => {
        if (response && response.data.msg) {
          context.commit(SET_ERROR, [response.data.msg]);
        } else {
          context.commit(SET_ERROR, ['Erreur de connexion']);
        }
      });
    });
  },
  [OFFLINE](context) {
    context.commit(SET_OFFLINE, true);
  },
  [ONLINE](context) {
    if (context.getters.hasOfflineQuery) {
      const lang = context.getters.currentLang;
      const backToBusinessMessage = lang === 'fr' ? 'Vous êtes de nouveau connecté, vos saisies vont être renvoyées' : 'You are back online, your entries will be sent again';
      context.dispatch(INFO, backToBusinessMessage);
      context.commit(SET_HAS_OFFLINE_QUERY, false);
    }
    context.commit(SET_OFFLINE, false);
  },
  [HAS_OFFLINE_QUERY](context, hasOfflineQuery) {
    context.commit(SET_HAS_OFFLINE_QUERY, hasOfflineQuery);
  },
  [HAS_REPLAYED_QUERIES](context, nbQueries) {
    const lang = context.getters.currentLang;
    const backToBusinessMessage = lang === 'fr' ? `${nbQueries} requêtes ont été renvoyées` : `${nbQueries} queries have been sent again`;
    context.commit(HAS_OFFLINE_QUERY, false);
    context.dispatch(INFO, backToBusinessMessage);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    context.commit(PURGE_DATA);
  },
  [LOGIN_RESET](context) {
    context.commit(SET_ERROR, []);
  },
  [SET_LANGUAGE](context, data) {
    context.commit(SET_LANGUAGE, data.lang);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_OFFLINE](state, isOffline) {
    // console.log('SET_OFFLINE', isOffline);
    state.isOffline = isOffline;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.token = data.token;
    state.timestamp = Date.now();
    state.expires_in = DEFAULT_EXPIRATION * 1000;
    state.errors = {};
    JwtService.saveToken(data.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.token = null;
    state.timestamp = null;
    state.expires_in = null;
    JwtService.destroyToken();
  },
  [SET_LANGUAGE](state, lang) {
    if (state.user) {
      state.user.lang = lang.toLowerCase();
      i18n.locale = lang;
    }
  },
  [SET_HAS_OFFLINE_QUERY](state, hasOfflineQuery) {
    state.hasOfflineQuery = hasOfflineQuery;
  },
};

export default {
  state: stateAuth,
  actions,
  mutations,
  getters,
};
