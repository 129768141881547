import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome, faSignInAlt, faDog, faSignOutAlt, faEdit, faWindowClose, faSync, faSlidersH, faMars,
  faVenus, faExclamationTriangle, faComment, faCertificate, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Toasted from 'vue-toasted';

import Vue from 'vue';
import ApiService from './common/api.service';

import App from './App.vue';
import './registerServiceWorker';
// import './swRegistration';
import store from './store';
import router from './router';

import i18n from './i18n';

Vue.use(Toasted, {
  duration: 4000,
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

library.add([faSignInAlt, faHome, faDog, faSignOutAlt, faEdit, faWindowClose, faSync, faSlidersH,
  faMars, faVenus, faExclamationTriangle, faCertificate, faComment, faCheck]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

ApiService.init();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
