export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const PING = 'ping';
export const LOGOUT = 'logout';
export const LOGIN_RESET = 'login.reset';
export const SET_LANGUAGE = 'setLanguage';
export const FETCH_EXPO = 'fetchExpo';
export const TOGGLE_NAV = 'toggleNav';
export const HAS_OFFLINE_QUERY = 'hasOfflineQuery ';
export const HAS_REPLAYED_QUERIES = 'hasReplayedQueries';
export const OFFLINE = 'offline';
export const ONLINE = 'online';
export const EVALUATE_DOG = 'evaluateDog';
export const CHANGE_SIGNATURE = 'changeSignature';
export const VALIDATE_NOTATIONS = 'validateNotations';
export const REORDER_QUALIFIER = 'reorderQualifier';
export const CHANGE_POSITION = 'changePosition';
export const IS_VALIDATED = 'isValidated';
export const ERROR = 'error';
export const INFO = 'info';
