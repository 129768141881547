<template>
  <div class="choix-langue">
    <div
      v-for="(lang, i) in languageArray"
      :key="`lang${i}`"
      :class="lang + (lang === language ? ' active': '')"
      @click="onChangeLanguage(lang)" ></div>
  </div>
</template>

<script>
/* eslint-disable object-shorthand */

import { mapGetters } from 'vuex';

import { languages } from '../i18n';

import { SET_LANGUAGE } from '../store/const/actions.type';

export default {
  name: 'choix-langue',
  data() {
    return {
      languageArray: languages,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
    language: {
      get: function () {
        return this.$store.state.auth.user.lang;
      },
      set: function (newVal) {
        this.$store.dispatch(SET_LANGUAGE, { lang: newVal });
      },
    },
  },
  methods: {
    onChangeLanguage(lang) {
      this.$store.dispatch(SET_LANGUAGE, { lang }).then(() => {
        // this.$i18n.locale = lang; // i18N ! important
      });
    },
  },
};
</script>

<style lang="scss">
  .choix-langue {
    display: flex; padding-top:1rem; ; align-items: start ;
    > div {
      display: inline-block; opacity: .7; line-height: 2rem; margin: 0 .5rem;
      ; height: 2rem; cursor: pointer; color: white;
      &.active {opacity: 1; cursor: default}
      background-size: contain; background-repeat: no-repeat; width: 40px;
      background-position: center;
      &.en {background-image: url('~@/assets/flag-uk.png');}
      &.fr {background-image: url('~@/assets/flag-fr.png');}
    }
  }
</style>
