import Vue from 'vue';

const stateAlert = {
  type: null,
  message: null,
};

const getters = {
  hasError(state) {
    return state.message !== null;
  },
  getMsg(state) {
    return state.message;
  },
};

const actions = {
  success({ commit }, message) {
    Vue.toasted.success(message);
    commit('success', message);
  },
  info({ commit }, message) {
    Vue.toasted.info(message);
    commit('info', message);
  },
  error({ commit }, message) {
    commit('error', message);
    Vue.toasted.error(message);
    commit('clear', message); // yay
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  success(state, message) {
    state.type = 'alert-success';
    state.message = message;
  },
  info(state, message) {
    state.type = 'alert-info';
    state.message = message;
  },
  error(state, message) {
    state.type = 'alert-danger';
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

export default {
  state: stateAlert,
  actions,
  mutations,
  getters,
};
