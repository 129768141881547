<template>
  <div id="app" v-bind:class="{ dev: isDevelopmentMode }">
    <cedia-header />
    <div class="main">
      <router-view></router-view>
    </div>
<!--    <cedia-footer />-->
  </div>
</template>

<style lang="scss">
  body {
    padding-top: 60px; padding-bottom: 50px;
    font-family: Roboto; font-size: 12px; background-color: white;
  }
  @font-face {
    font-family: Roboto;
    src:url('./assets/roboto/Roboto-Regular.ttf') format("truetype");
  }
  #app {
    &.dev {
      //border-left: 1rem #ffcc57 dashed;
      //border-right: 1rem #ffcc57 dashed;
      .cedia-header {background-color: #fde6b3}
      .cedia-header .city::before {content: 'DEV - '; color: red}
    }

    .cedia-header {
      position:fixed; width: 100%; top:0; background: white;
      .container {padding:.5rem}
    }
    .main {
      padding:.5rem;
      .card {
        max-width: 380px; padding: 15px 35px 45px; margin: 0 auto; background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.18);
      }
      h1 {font-size: min(1.4rem, 4vw); color: #266f7e; text-align: center;
        text-transform: uppercase;font-weight: 500}

      a { color: #266f7e; font-weight: 500; }
      button { font-family: Roboto}
    }
    .warning { color:darkred }
    .information { color:darkblue }
    footer {position: fixed;bottom: 0}
    .message {
      padding: 1rem; margin: 1rem 0;
      &.information {
        background-color: #d4edda; color: #155724; border-color: #c3e6cb;
      }
    }

  }

</style>
<script>
import CediaHeader from '@/components/Header.vue';

export default {
  components: { CediaHeader },
  computed: {
    isDevelopmentMode() { return process.env.VUE_APP_DEV === 'true'; },
  },
};
</script>
