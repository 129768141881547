<template>
  <div class="burger" :class="{ 'active' : isNavOpen }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { TOGGLE_NAV } from '../../store/const/actions.type';

export default {
  computed: {
    ...mapGetters([
      'isNavOpen',
    ]),
  },
  methods: {
    toggle() {
      this.$store.dispatch(TOGGLE_NAV); // .then(() => console.log('Toggled nav'));
    },
  },
};
</script>
<style lang="scss">
  .burger{
    .hidden { visibility: hidden; }

    button { cursor: pointer; }

    /* remove blue outline */
    button:focus { outline: 0; }

    .burger-button {
      position: relative; height: 30px; width: 32px; display: block; margin-left:auto;
      z-index: 999; border: 0; border-radius: 0; background-color: transparent;
      pointer-events: all; transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar {
      background-color: #130f40; position: absolute; top: 50%;
      right: 6px; left: 6px; height: 2px; width: auto; margin-top: -1px;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar--1 { -webkit-transform: translateY(-6px); transform: translateY(-6px); }
    .burger-bar--2 { transform-origin: 100% 50%; transform: scaleX(0.8); }

    .burger-button:hover .burger-bar--2 { transform: scaleX(1); }
    .no-touchevents .burger-bar--2:hover { transform: scaleX(1); }

    .burger-bar--3 { transform: translateY(6px); }

    &.active .burger-button { transform: rotate(-180deg); }
    &.active .burger-bar { background-color: #fff; }

    &.active .burger-bar--1 { transform: rotate(45deg); }
    &.active .burger-bar--2 { opacity: 0; }
    &.active .burger-bar--3 { transform: rotate(-45deg);}
  }
</style>
