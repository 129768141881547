import Vue from 'vue';
import axios from 'axios';
import JwtService from '@/common/jwt.service';
import VueAxios from 'vue-axios';

import { PermissionError, ServerError, NetworkError } from '../store/const/exceptions';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  setHeader() {
    const token = JwtService.getToken();
    Vue.axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params) {
    this.setHeader(); // auth required
    return Vue.axios.post(`${resource}`, params)
      .then((response) => response)
      .catch((error) => {
        if (navigator.onLine === false) {
          throw new NetworkError();
        }
        // console.error('ApiService', error.response.status);
        if (error.response.status === 401) {
          throw new PermissionError();
        }
        if (error.response.status >= 500) {
          throw new ServerError();
        }
        throw new Error(`ApiService ${error}`);
      });
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },
};

export default ApiService;

export const ExpoService = {
  query(type, params) {
    ApiService.setHeader(); // auth required
    return ApiService.query('expos', {
      params,
    });
  },
};
