<template>
  <div class="sidebar" @keydown.esc="closeSidebarPanel" tabindex="0">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isNavOpen"></div>
    <transition name="slide">

      <div v-if="isNavOpen" class="sidebar-panel">
        <font-awesome-icon icon="window-close" @click="closeSidebarPanel" />
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { TOGGLE_NAV } from '../../store/const/actions.type';

export default {
  methods: {
    closeSidebarPanel() {
      this.$store.dispatch(TOGGLE_NAV).then(() => console.log('Closed nav'));
    },
  },
  computed: {
    ...mapGetters([
      'isNavOpen',
    ]),
  },
};
</script>
<style lang="scss">
  .sidebar {
    position: relative;
    .fa-window-close {
      width: 32px; height: 30px; color: white;
      position: relative; float: right;
      &:hover {color: black; background: white; cursor: pointer;}
    }

    .slide-enter-active,
    .slide-leave-active {
      transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
      transform: translateX(100%);
      transition: all 150ms ease-in 0s
    }

    .sidebar-backdrop {
      background-color: rgba(19, 15, 64, 0.41); width: 100vw; height: 100vh;
      position: fixed; top: 0; right: 0; cursor: pointer;
    }

    .sidebar-panel {
      overflow-y: auto; background-color: #544948; position: fixed;
      right: 0; top: 0; height: 100vh; z-index: 999;
      padding: 1rem 20px 2rem 20px; width: 300px;

      li {
        list-style-type: none; display: block;

        &.home {margin-bottom: 7rem;}
        &.entry {border-bottom: 1px gray solid; width:200px; margin-left: 1.5rem;}
        &.lang {position: absolute; bottom: 7rem; width: 200px;}

        a {
          color: #fff; text-decoration: none; font-size: 1.5rem;
          padding-bottom: 0.5em;
        }
      }
    }
  }
</style>
