// eslint-disable-next-line max-classes-per-file
class PermissionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PermissionError';
    this.translatedMessage = 'closed_error';
  }
}

class ServerError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ServerError';
    this.translatedMessage = 'server_error';
  }
}

class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NetworkError';
    this.translatedMessage = 'network_error';
  }
}

export { NetworkError, PermissionError, ServerError };
