import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import localforage from 'localforage';

import auth from './auth.module';
import expo from './expo.module';
import notations from './notations.module';
import ui from './ui.module';
import alert from './alert.module';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'cedia-jugement', // The key to store the state on in the storage provider.
  storage: localforage,
  asyncStorage: true,
  reducer: (state) => ({
    auth: state.auth,
    expo: state.expo,
    notations: state.notations,
  }),
});

export default new Vuex.Store({
  state: { auth: null },
  mutations: {},
  actions: {},
  plugins: [
    vuexLocalStorage.plugin,
  ],
  modules: {
    alert,
    auth,
    expo,
    ui,
    notations,
  },
});
