import { TOGGLE_NAV } from './const/actions.type';

const stateUI = {
  navOpen: false,
};

const getters = {
  isNavOpen(state) {
    return state.navOpen;
  },
};

// eslint-disable-next-line import/prefer-default-export
const actions = {
  async [TOGGLE_NAV]({ commit }) {
    commit(TOGGLE_NAV);
  },
};

const mutations = {
  [TOGGLE_NAV](state) {
    state.navOpen = !state.navOpen;
  },
};

export default {
  state: stateUI,
  actions,
  mutations,
  getters,
};
