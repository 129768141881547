/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from '../i18n';
import { TOGGLE_NAV } from '../store/const/actions.type';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/accueil/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/expos',
    name: 'expos',
    component: () => import('../views/expos.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/race/:id/:dog_id?',
    name: 'race',
    component: () => import('../views/race.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import('../views/recompenses.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/rewards/:race_id',
    name: 'reward',
    component: () => import('../views/recompense.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/validation',
    name: 'validation',
    component: () => import('../views/validation.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/manual-edit',
    name: 'manual_edits',
    component: () => import('../views/manual_edit.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/chien/:id',
    name: 'chien',
    component: () => import('../views/chien.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  { path: '*', redirect: '/' }, /* otherwise */
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// patch : le stockage est asynchrone (il faut attendre qu'il soit prêt)
const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;

  if (store.state.auth.user.lang) {
    i18n.locale = store.state.auth.user.lang.toLowerCase(); // init language
  }
  next();
};
router.beforeEach(waitForStorageToBeReady);

// @see https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router
router.beforeEach((to, from, next) => {
  if (store.getters.isNavOpen) {
    store.dispatch(TOGGLE_NAV).then(() => {});
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    }
  }
  // other routes / authenticated user
  next();
});

export default router;
