/**
 * Méthode de récupération (lecture) des données
 */
import { ExpoService } from '@/common/api.service';

import { FETCH_EXPO } from './const/actions.type';
import {
  FETCH_START, FETCH_END, SET_EVALUATION, SET_POSITION, PURGE_DATA,
} from './const/mutations.type';

const stateExpo = {
  expo: {},
  races: [],
  chiens: [],
  classes: [],
  notations: [],
  isLoading: true,
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  currentExpo(state) {
    return state.expo;
  },

  isValidated(state) {
    return state.expo && state.expo.est_finalise === '1';
  },

  // ****************** DOGS
  dogsByRace(state) {
    return state.chiens.reduce((arr, c) => {
      // eslint-disable-next-line no-param-reassign
      if (!(c.race_id in arr)) { arr[c.race_id] = []; }
      arr[c.race_id].push(c);
      return arr;
    }, {});
  },
  getAllDogs(state) {
    return state.chiens;
  },
  getDogsByRaceId: (state) => (raceId) => state.chiens.filter((chien) => chien.race_id === raceId),
  getDogsBy: (state) => (filters) => state.chiens.filter((chien) => (
    chien.classe_id === filters.classId)
      && (chien.nr_sexe === filters.sex)
      && (chien.race_id === filters.raceId)),
  getDogById: (state) => (dogId) => state.chiens.find((chien) => chien.nr_cat === dogId),
  getNextDog: (state, stateGetters) => function (dogId) {
    const dog = stateGetters.getDogById(dogId);
    if (!dog) {
      return null;
    }
    let dogsInRace = stateGetters.getDogsByRaceId(dog.race_id);
    dogsInRace = stateGetters.sortByNrCat(dogsInRace);
    // const dogsInRace = stateGetters.getDogsByRaceId(dog.race_id);
    const pos = dogsInRace.findIndex((d) => d.nr_cat === dogId);
    if (pos === false || pos === dogsInRace.length) {
      return null;
    }
    return dogsInRace[pos + 1];
  },
  getPrevDog: (state, stateGetters) => function (dogId) {
    // console.log('get prev', dogId);
    const dog = stateGetters.getDogById(dogId);
    if (!dog) {
      return null;
    }

    // const dogsInRace = stateGetters.getDogsByRaceId(dog.race_id);
    let dogsInRace = stateGetters.getDogsByRaceId(dog.race_id);
    dogsInRace = stateGetters.sortByNrCat(dogsInRace);
    const pos = dogsInRace.findIndex((d) => d.nr_cat === dogId);
    // console.log(dog, dogsInRace, pos);
    if (pos === false || pos === 0) {
      return null;
    }
    return dogsInRace[pos - 1];
  },

  sortByNrCat: () => (dogs) => dogs.sort((a, b) => {
    if (a.ordre_classe === b.ordre_classe) {
      return a.nr_sexe.localeCompare(b.nr_sexe) || a.nr_cat - b.nr_cat; // || a.pos - b.pos;
    }

    return a.ordre_classe > b.ordre_classe ? 1 : -1;
  }),

  getDogsByClassAndSex: (state, stateGetters) => function (raceId, lng) {
    let dogs = stateGetters.getDogsByRaceId(raceId);
    dogs = stateGetters.sortByNrCat(dogs);

    // console.log(dogs);
    const byClassAndSex = dogs.reduce((arr, d) => {
      const identifier = `${d.classe_id}--${d.nr_sexe}`;
      if (!(identifier in arr)) {
        // eslint-disable-next-line no-param-reassign
        arr[identifier] = [];
      }
      arr[identifier].push(d);
      return arr;
    }, {});

    return Object.keys(byClassAndSex).map((classAndSex) => {
      const classId = byClassAndSex[classAndSex][0].classe_id;
      const sex = byClassAndSex[classAndSex][0].nr_sexe;
      const classOrder = byClassAndSex[classAndSex][0].ordre_classe;
      const classRef = classAndSex.split('--')[0];

      const classLabel = stateGetters.getClassById(classRef)[lng];

      return {
        name: classLabel,
        key: `${classLabel}-${sex}`,
        id: classId,
        order: classOrder,
        dogs: byClassAndSex[classAndSex].sort((a, b) => a.nr_cat - b.nr_cat),
      };
    });
  },

  /**
   * Récupération des chiens de la race donnée (raceId)
   * groupés par classe/sexe (selon langue)
   * todo remove
   */
  getDogsGroupedByClassId: (state, stateGetters) => function (raceId, lng) {
    const dogs = stateGetters.getDogsByRaceId(raceId);
    console.group('By races');
    console.table(dogs);
    console.groupEnd();

    dogs.sort((a, b) => (a.ordre_classe === b.ordre_classe
      ? a.pos - b.pos : a.ordre_classe - b.ordre_classe));
    // console.log(dogs);
    const byClass = dogs.reduce((arr, d) => {
      if (!(d.classe_id in arr)) {
        // eslint-disable-next-line no-param-reassign
        arr[d.classe_id] = [];
      }
      arr[d.classe_id].push(d);
      return arr;
    }, {});

    const res = Object.keys(byClass).map((classRef) => {
      const classId = byClass[classRef][0].classe_id;
      const sex = byClass[classRef][0].nr_sexe;
      const classOrder = byClass[classRef][0].ordre_classe;
      const classLabel = stateGetters.getClassById(classRef)[lng];
      return {
        name: classLabel,
        key: `${classLabel}-${sex}`,
        id: classId,
        order: classOrder,
        dogs: byClass[classRef],
      };
    });
    res.sort((a, b) => ((a.order === b.order) ? 0 /* a.name > b.name */ : a.order - b.order));
    // console.log(res);
    // byClassId.reduce((arr, classId) => {
    //   const classLabel = stateGetters.getClassById(classId)[lng];
    //   // eslint-disable-next-line no-param-reassign
    //   arr[classLabel] = byClassId[classId];
    //   return arr;
    // });
    //
    // for (const key of Object.keys(byClassId)) {
    //   const classLabel = stateGetters.getClassById(classId)[lng];
    //   res[classLabel] = byClassId[key];
    // }
    return res;
    //
    // return res.reduce((arr, classId) => {
    //   console.log(classId);
    //   const classLabel = stateGetters.getClassById(classId)[lng];
    //   // eslint-disable-next-line no-param-reassign
    //   arr[classLabel] = classId;
    //   return arr;
    // }, {});
  },

  // ****************** RACES
  getRaceById: (state) => (raceId) => state.races.find((race) => race.id === raceId),
  getAllRaces: (state) => () => state.races,

  // ****************** CLASSES
  getClassById: (state) => (classId) => state.classes.find((clz) => clz.id === classId),
  getClassByLabel: (state) => function (label, lang) {
    if (lang === 'fr') {
      return state.classes.find((clz) => clz.fr === label);
    }
    return state.classes.find((clz) => clz.en === label);
  },

  // ****************** QUALIFIERS
  getQualifiersByClassId: (state, stateGetters) => function (classId) {
    const claz = stateGetters.getClassById(classId);
    if (claz) {
      return claz.qualificatifs;
    }
    return [];
  },
  getQualifierById: (state, stateGetters) => function (classId, qualifierId) {
    // console.log('getQualifierById', classId, qualifierId);
    const qualifiers = stateGetters.getQualifiersByClassId(classId);
    // console.log(classId, qualifiers, qualifierId, qualifiers.find((q) => q.id === qualifierId));
    return qualifiers.find((q) => q.id === qualifierId);
  },
};

// eslint-disable-next-line import/prefer-default-export
const actions = {
  async [FETCH_EXPO]({ commit }) {
    return ExpoService.query({}, {}).then(({ data }) => {
      // console.log(data);
      commit(FETCH_START);
      commit(FETCH_END, data);
    }).catch((error) => {
      console.error(error);
      // throw new Error(error);
    });
  },
};

const mutations = {
  [FETCH_START](state) {
    state.isLoading = true;
    state.expo = {};
    state.chiens = [];
    state.races = [];
    state.classes = [];
  },
  [FETCH_END](state, data) {
    state.isLoading = false;
    state.expo = data.expo;
    state.chiens = data.chiens;
    state.races = data.races;
    state.classes = data.classes;
    // console.log('FETCH END', state.expo, state.chiens, state.races, state.classes);
  },
  [SET_EVALUATION](state, payload) {
    const dog = state.chiens.find((d) => d.nr_cat === payload.nr_cat);
    dog.commentaire = payload.comment;
    dog.qualificatif_id = payload.qualifier ?? '';
    dog.classement = payload.classement;
    console.log('SET_EVALUATION', dog, payload);
    let notation = state.notations.find((d) => d.nr_cat === payload.nr_cat);
    if (!notation) {
      notation = {};
      state.notations.push(notation);
    }
    Object.assign(notation, {
      nr_cat: dog.nr_cat,
      commentaire: dog.commentaire,
      qualificatif_id: dog.qualificatif_id,
      classement: dog.classement,
    });
  },
  [SET_POSITION](state, payload) {
    const dogNrCat = payload.nr_cat;
    const dog = state.chiens.find((d) => d.nr_cat === dogNrCat);
    dog.classement = payload.classement;
  },
  [PURGE_DATA](state) {
    state.expo = {};
    state.chiens = [];
    state.races = [];
    state.classes = [];
  },
};

export default {
  state: stateExpo,
  actions,
  mutations,
  getters,
};
