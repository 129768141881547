/**
 * Méthodes de notation
 */
import ApiService from '@/common/api.service';
import {
  CHANGE_POSITION,
  EVALUATE_DOG,
  REORDER_QUALIFIER,
  VALIDATE_NOTATIONS,
  CHANGE_SIGNATURE,
} from './const/actions.type';
import {
  INIT_APP,
  RESET_EVALUATIONS,
  SET_ERROR,
  SET_EVALUATION,
  SET_POSITION,
  SET_SIGNATURE,
  HAS_VALIDATED,
} from './const/mutations.type';

const stateNotations = {
  errors: [],
  signature: '',
  is_validated: false,
};

const getters = {
  getAllResults: (state, stateGetters) => stateGetters.getAllDogs
    .filter((d) => d.qualificatif_id !== ''),
  /**
   * Récupération générique par race/classe/qualificatif
   * */
  getResults: (state, stateGetters) => function (raceId, classId, qualifierId) {
    return stateGetters.getAllDogs.filter((d) => d.race_id === raceId && d.classe_id === classId
      && d.qualificatif_id === qualifierId)
      .sort((a, b) => a.classement - b.classement);
  },
  /**
   * Récupération de chiens à "positionner" dans une classe (nb .
   * */
  getWithRanking: (state, stateGetters) => function (raceId, classId, sex) {
    const qualifiers = stateGetters.getQualifiersByClassId(classId).reduce((arr, q) => {
      if (q.nb_Classement > 0) {
        arr.push(q.id);
      }
      return arr;
    }, []);
    const withRanking = stateGetters.getAllDogs
      .filter((d) => d.race_id === raceId && d.classe_id === classId
        && qualifiers.includes(d.qualificatif_id) && d.nr_sexe === sex)
      .map((c) => c.nr_cat);
    return withRanking;
  },
  /**
   * Récupération des chiens encore à juger :
   * tous les chiens par race - ceux déjà jugés (reduce) - ceux sans
   * qualificatifs (ne concourant pas)
   */
  getDogsToEvaluateByRace: (state, stateGetters) => {
    const dogs = stateGetters.dogsByRace;
    return Object.keys(dogs).reduce((arr, raceId) => {
      const currentRace = [];
      for (let i = 0; i < dogs[raceId].length; i += 1) {
        const dog = dogs[raceId][i];
        if (dog.qualificatif_id === '') {
          // check classId
          const clazz = stateGetters.getClassById(dog.classe_id);
          if (clazz.qualificatifs.length > 0) {
            currentRace.push(dog);
          }
        }
      }
      // eslint-disable-next-line no-param-reassign
      arr[raceId] = currentRace;
      return arr;
    }, {});
  },
  getDogsStatusByRace: (state, stateGetters) => {
    const dogs = stateGetters.dogsByRace;
    const res = Object.keys(dogs).reduce((arr, raceId) => {
      const currentRace = {
        todo: [],
        done: [],
        status: 'todo',
        total: dogs[raceId].length,
        id: raceId,
      };
      for (let i = 0; i < dogs[raceId].length; i += 1) {
        const dog = dogs[raceId][i];
        if (dog.qualificatif_id === '') {
          // check classId
          const clazz = stateGetters.getClassById(dog.classe_id);
          if (clazz.qualificatifs.length > 0) {
            currentRace.todo.push(dog);
          } else {
            currentRace.total -= 1;
            console.error('Attention, chien ne concourant pas :', dog.nr_cat, dog.nom_chien);
          }
        } else {
          currentRace.done.push(dog);
        }
        currentRace.ordre_race = dog.ordre_race;
      }
      currentRace.status = (currentRace.todo.length === 0) ? 'done' : 'todo';
      arr.push(currentRace);
      return arr;
    }, []);

    return res.sort((a, b) => a.ordre_race - b.ordre_race);
  },
  /**
   * races
   */
  getRacesToEvaluate: (state, stateGetters) => {
    const dogs = stateGetters.dogsByRace;
    // const unsorted = state.chiens.reduce((arr, c) => {
    const res = Object.keys(dogs).reduce((arr, raceId) => {
      const currentRace = { done: [], todo: [], id: raceId };
      for (let i = 0; i < dogs[raceId].length; i += 1) {
        const dog = dogs[raceId][i];
        if (dog.qualificatif_id === '') {
          // check classId
          const clazz = stateGetters.getClassById(dog.classe_id);
          if (clazz.qualificatifs.length > 0) {
            currentRace.todo.push(dog);
          }
        } else {
          currentRace.done.push(dog);
        }
        currentRace.ordre_race = dog.ordre_race;
      }
      arr.push(currentRace);
      return arr;
    }, []);
    // sort by
    return res.sort((a, b) => a.ordre_race - b.ordre_race);
  },

  /**
   * Récupération des récompenses par race
   */
  getSummary: (state, stateGetters) => {
    const allDogs = stateGetters.getAllResults.map((d) => {
      const dogResults = {
        nr_cat: d.nr_cat,
        race_id: d.race_id,
        sexe: d.nr_sexe,
        classe_id: d.classe_id,
        qualificatif_id: d.qualificatif_id,
        classement: d.classement > 0 ? d.classement : '',
        recompenses: d.recompenses ? d.recompenses.split(',') : [],
        commentaire: d.commentaire,
      };
      return dogResults;
    });
    // group dogs by race_id
    const dogs = allDogs.reduce((arr, d) => {
      const raceId = d.race_id;
      if (!arr[raceId]) {
        // eslint-disable-next-line no-param-reassign
        arr[raceId] = [];
      }
      arr[raceId].push(d);
      return arr;
    }, {});
    // console.log(dogs);

    return dogs;
  },
  /**
   * Récupération des bornes nr_cat
   */
  getNrCatBoundaries: (state, stateGetters) => {
    // array.reduce(function(total, curValue, curIndex, arr), initialValue)
    let min = 99999999;
    let max = 0;
    const allDogs = stateGetters.getAllDogs;
    allDogs.forEach((dog) => {
      const thisNrCat = parseInt(dog.nr_cat, 10);
      if (thisNrCat < min) {
        min = thisNrCat;
      }
      if (thisNrCat > max) {
        max = thisNrCat;
      }
    });
    return { min, max };
  },

  /**
   * Récupération des résultats pour un chien donné
   */
  getDogResult: (state, stateGetters) => function (dogId) {
    const dog = stateGetters.getDogById(dogId);
    // console.log('getDogResult', dogId, dog);
    if (dog) {
      const currentDogsWithRanking = stateGetters.getWithRanking(dog.race_id, dog.classe_id,
        dog.nr_sexe);
      let posMax = currentDogsWithRanking.length;
      // console.log(stateGetters.getQualifierById(dog.classe_id, dog.qualificatif_id).lib_fr,
      //   posMax, currentDogsWithRanking);
      const qualifier = stateGetters.getQualifierById(dog.classe_id, dog.qualificatif_id);
      posMax = Math.min(posMax, qualifier ? qualifier.nb_Classement : 0);
      const posMin = 1;

      return {
        nr_cat: dog.nr_cat,
        qualifier: dog.qualificatif_id,
        comment: dog.commentaire,
        classement: dog.classement,
        recompenses: dog.recompenses,
        posMin,
        posMax,
      };
    }
    return null;
  },
  canShowPreview: (state, stateGetters) => function () {
    return Object.entries(stateGetters.getRacesToEvaluate())
      .reduce((acc, dogs) => acc && dogs[1].todo.length === 0, true);
  },
  /**
   * Returning dogs with reward (for a given class)
   */
  getDogsWithReward: (state, stateGetter) => function (raceId) {
    const allDogs = stateGetter.getDogsByRaceId(raceId);
    return allDogs.filter((d) => d.recompenses !== '' && d.recompenses !== undefined);
  },
  hasDogReward: (state, stateGetter) => function (dogId, reward) {
    const dog = stateGetter.getDogById(dogId);
    if (!dog) { return false; }
    if (Array.isArray(reward)) { // multiple possible rewards
      for (let i = 0; i < reward.length; i += 1) {
        const singleReward = reward[i];
        if (dog.recompenses.split(',').find((r) => r === singleReward)) {
          return true;
        }
      }
      return false;
    }
    // if (dog) {
    //   console.log('hasDogReward', dogId, reward, dog.recompenses);
    // }

    return dog && dog.recompenses.split(',').find((r) => r === reward);
  },
  getSignature: (state) => state.signature,
  getErrors: (state) => function () {
    return state.errors;
  },
  // isValidated: (state) => state.is_validated,
};

// eslint-disable-next-line import/prefer-default-export
const actions = {
  async [EVALUATE_DOG](context, data) { // dog + comment + qualifier + position + recompenses
    // Récupération du qualificatif actuel et du nombre de chiens à classer.
    const clazz = context.getters.getClassById(data.dog.classe_id);
    const qualifier = clazz.qualificatifs.find((q) => q.id === data.qualifier);
    const maxPos = qualifier ? qualifier.nb_Classement : 0; // TODO minuscules!

    let classement = 0; // data.classement ?? 0;
    let qualifierChanges = false;
    let oldQualifier = null;

    // Classement : cas modification d'un chien avec même qualification
    // (ex. changement commentaire).
    const dogsByQualifier = context.getters.getResults(
      data.dog.race_id, data.dog.classe_id, data.qualifier,
    );
    const nbPotentialDogs = context.getters.getDogsBy(
      { classId: data.dog.classe_id, sex: data.dog.nr_sexe, raceId: data.dog.race_id },
    ).length;

    if (dogsByQualifier.find((d) => d.nr_cat === data.dog.nr_cat)) {
      classement = data.dog.classement; // On conserve ce classement (non modifié)
    } else {
      qualifierChanges = data.dog.qualificatif_id !== '';
      oldQualifier = data.dog.qualificatif_id;
      classement = nbPotentialDogs === 1 ? 1 : 0; // no dog in this qualification => pos 1
    }
    if (maxPos === 0) {
      classement = 0;
    }

    await context.commit(SET_EVALUATION, {
      nr_cat: data.dog.nr_cat,
      comment: data.comment,
      qualifier: data.qualifier,
      classement,
    });

    // Changement de qualificatif : besoin de réordonner l'ancien (tous les chiens > classement)
    if (qualifierChanges) {
      context.dispatch(REORDER_QUALIFIER, {
        race_id: data.dog.race_id,
        classe_id: data.dog.classe_id,
        qualification_id: oldQualifier,
        classement: data.dog.classement,
        step: 1,
      });
    }

    const judgingResp = await ApiService.post('jugement/', {
      nr_cat: data.dog.nr_cat,
      nr_saisie: data.dog.nr_saisie,
      chien_id: data.dog.id,
      classe_id: data.dog.classe_id,
      qualificatif_id: data.qualifier,
      classement,
      commentaire: data.comment,
      recompenses: data.dog.recompenses || '',
    });

    if (judgingResp.status !== 200) {
      // caught elsewhere
    }
  },
  async [CHANGE_POSITION](context, data) { // dog + from_pos + to_pos
    // console.log('Changing position/ API TODO', data);

    const dog = context.getters.getDogById(data.nr_cat);
    const classement = data.to_pos;

    if (!dog) {
      // console.error('Missing dog (change position) : ', data.nr_cat);
      context.commit(SET_ERROR, ['Pb d\'acces (chien)']);
      return;
    }

    // const judgingResp =
    ApiService.post('jugement/', {
      nr_cat: dog.nr_cat,
      nr_saisie: dog.nr_saisie,
      classe_id: dog.classe_id,
      qualificatif_id: dog.qualificatif_id,
      classement,
      commentaire: dog.commentaire,
    }).finally(() => {
      context.commit(SET_POSITION, {
        nr_cat: data.nr_cat,
        classement,
      });
    });
    // if (judgingResp.status !== 200) {
    //   return; // caught elsewhere
    // }
  },
  /**
   * Mise à jour des classements d'un qualificatif
   */
  [REORDER_QUALIFIER](context, data) { // race_id, classe_id, qualification_id, classement, step
    // const qualifier = context.getters.getQualifierById(data.classe_id, data.qualification_id);
    const dogsByQualifier = context.getters.getResults(
      data.race_id, data.classe_id, data.qualification_id,
    );

    // console.log('REORDER_QUALIFIER', dogsByQualifier);
    // const maxPos = qualifier.nb_Classement;
    // for (let i = 0; i < maxPos; i += 1) {
    //   console.log(i);
    // }

    dogsByQualifier.forEach((d) => {
      if (d.classement > data.classement) {
        context.dispatch(CHANGE_POSITION, {
          nr_cat: d.nr_cat,
          from_pos: d.classement,
          to_pos: d.classement - data.step,
        });
      }
    });
  },
  async [VALIDATE_NOTATIONS](context, data) {
    // const validationResp = await ApiService.post('validation_err/', {
    const validationResp = await ApiService.post('validation/', {
      image_validation: data.img,
      id_juge: data.id_juge,
      prem_cat: data.prem_cat,
      der_cat: data.der_cat,
    });
    // console.log('??', validationResp);

    if (validationResp.status !== 200) {
      return; // caught elsewhere
    }

    context.commit(SET_SIGNATURE, [data.signature]);
    context.commit(HAS_VALIDATED, [data]);
  },
  [CHANGE_SIGNATURE](context, data) {
    context.commit(SET_SIGNATURE, data);
  },
};

const mutations = {
  [RESET_EVALUATIONS](state) {
    state.results = []; // todo remove
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SIGNATURE](state, signature) {
    state.signature = signature;
  },
  [HAS_VALIDATED](state) {
    state.is_validated = true;
  },
  [INIT_APP](state) {
    // console.log('INIT_APP');
    state.signature = null;
  },
};

export default {
  state: stateNotations,
  actions,
  mutations,
  getters,
};
