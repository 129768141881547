export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const INIT_APP = 'initApp';
export const SET_ERROR = 'setError';
export const SET_OFFLINE = 'setOffline';
export const SET_ONLINE = 'setOnline';
export const FETCH_START = 'fetchStart';
export const FETCH_END = 'fetchEnd';
export const SET_EVALUATION = 'setEvaluation';
export const SET_POSITION = 'setPosition';
export const RESET_EVALUATIONS = 'resetEvaluations';
export const PURGE_DATA = 'purgeData';
export const SET_SIGNATURE = 'setSignature';
export const HAS_VALIDATED = 'hasValidated';
export const SET_HAS_OFFLINE_QUERY = 'setHasOfflineQuery';
