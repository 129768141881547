<template>
  <nav class="cedia-header">

    <div class="container">
      <router-link class="home" active-class="active" exact :to="{ name: 'home' }">

        <div class="logo"></div>

        <span class="city" :title="currentExpo.ville" v-text="getTitle()" />
        <div class="offline"
          v-bind:class="{'show': this.showOffline}"
        ></div>
      </router-link>

      <burger></burger>
      <sidebar>
        <ul v-if="!isAuthenticated">
          <li class="home">
            <router-link :to="{ name: 'home' }">
              {{ $t('nav.title')}}
            </router-link>
          </li>
          <li class="entry">
            <font-awesome-icon icon="home" />
            <router-link class="nav-link" active-class="active" exact :to="{ name: 'home' }">
              {{ $t('nav.home') }}
            </router-link>
          </li>
          <li class="entry">
            <font-awesome-icon icon="sign-in-alt" />
            <router-link class="nav-link" active-class="active" exact :to="{ name: 'login' }">
              {{ $t('nav.login') }}
            </router-link>
          </li>
        </ul>
        <ul v-else>
          <li class="home">
            <router-link :to="{ name: 'home' }">
              {{ $t('nav.title')}}
            </router-link>
          </li>
          <li class="entry" v-if="! isValidated">
            <font-awesome-icon icon="dog" />
            <router-link class="nav-link" active-class="active" :to="{ name: 'expos' }">
              <i class="ion-compose"></i>{{ $t('nav.shows')}}
            </router-link>
          </li>
          <li class="entry" v-if="! isValidated">
            <font-awesome-icon icon="sign-in-alt" />
            <router-link class="nav-link" active-class="active" exact :to="{ name: 'rewards' }">
              <i class="ion-compose"></i>{{ $t('nav.rewards')}}
            </router-link>
          </li>

          <li class="entry" v-if="canShowPreview">
            <font-awesome-icon icon="check" />
            <router-link class="nav-link" active-class="active" exact :to="{ name: 'validation' }">
              <i class="ion-compose"></i>{{ $t('nav.validation')}}
            </router-link>
          </li>

          <li class="entry">
            <font-awesome-icon icon="sign-out-alt" />
            <a @click="onLogout">{{ $t('nav.logout') }}</a>
          </li>
          <li class="lang">
            <choix-langue></choix-langue>
          </li>
          <li class="copyright">
            Copyright {{ (new Date()).getFullYear() }}
          </li>
        </ul>

      </sidebar>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

// import CediaMenu from '@/components/Menu.vue';
import Burger from '@/components/Menu/Burger.vue';
import Sidebar from '@/components/Menu/Sidebar.vue';
import ChoixLangue from '@/components/ChoixLangue.vue';

import {
  LOGOUT, INFO, TOGGLE_NAV, OFFLINE, ONLINE, HAS_REPLAYED_QUERIES,
} from '@/store/const/actions.type';

export default {
  name: 'CediaHeader',
  components: { Burger, Sidebar, ChoixLangue },
  computed: {
    ...mapGetters([
      'currentUser', 'isAuthenticated', 'getMsg', 'hasError', 'currentExpo',
      'canShowPreview', 'isValidated', 'isOffline']),
    showOffline() {
      return this.isOffline === true;
    },
  },
  methods: {
    onLogout() {
      if (!window.confirm(this.$t('global.confirm_logout'))) {
        return;
      }
      this.$store.dispatch(LOGOUT).then(() => {
        if (this.$router.currentRoute.name !== 'home') {
          this.$router.push({ name: 'home' });
        }

        this.$store.dispatch(INFO, this.$t('global.logout_success'));
        this.$store.dispatch(TOGGLE_NAV);
      });
    },
    getTitle() {
      if (this.currentExpo && this.currentExpo.ville) {
        return this.currentExpo.ville;
      }
      return this.$t('global.application_title');
    },
    updateOnlineStatus() {
      if (navigator.onLine) {
        this.$store.dispatch(ONLINE);
      } else {
        this.$store.dispatch(OFFLINE);
      }
    },
    handleSWMessage(event) {
      console.log('SW message', event);
      if (event.data.type === 'replay-complete') {
        const { replayCount } = event.data;
        this.$store.dispatch(HAS_REPLAYED_QUERIES, replayCount);
      }
    },
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    if ('serviceWorker' in navigator) {
      console.log('hello service worker');
      navigator.serviceWorker.addEventListener('message', this.handleSWMessage);
    }
  },
  destroyed() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.removeEventListener('message', this.handleSWMessage);
    }
  },
};
</script>
<style lang="scss">
  .cedia-header {
    border-bottom:1px #EEE solid; z-index: 2;
    height: 80px; width: 100%;
    > .container {
      margin:auto; height: 100%; align-items: center;
      display: flex; flex-direction: row;
      .city {text-overflow: ellipsis; white-space: nowrap; overflow: hidden}
      .home {
        flex: 0 500px; color:#1193A8; font-size:min(1.5rem, 5vw); font-weight: 300;
        display: flex; align-items: center; gap:.5rem;max-width: 90%}
      .brand {font-size: 1.3rem; color:#1193A8; font-weight: 500}
      .bull{ font-size: 2rem; line-height: 1rem; }
      .burger {flex:1}
    }

    .sidebar-panel li {
      padding-bottom: .5rem;
      svg { position: absolute; color: white; margin-right: .5rem; margin-top: .5rem;
        margin-left:-1.5rem;}
        &:hover { svg{color: #6792bc} }
      a {
        cursor: pointer;
        &.active { color: gray; cursor: default; }
      }
      &.copyright {color :white;     position: absolute;
        bottom: 4rem; left: 1.5rem;
        width: 200px;}
    }

    .logo {display: inline-block;
      background-image: url('~@/assets/logo_cedia.png'); width: 60px; height: 60px;
      background-size: 60px; flex-shrink: 0;
    }

    @keyframes blink {
      0% {opacity: 1;}
      50% {opacity: 0.5; transform: scale(0.8);}
      100% {opacity: 1;}
    }

    .offline {display: inline-block;
      background-image: url('~@/assets/offline.jpg'); width: 50px; height: 50px;
      background-size: 0;
      &.show {background-size: contain; animation: blink 1.5s infinite;}
    }
  }
</style>
